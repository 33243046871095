import React from 'react'
import SEO from '~/components/seo'
import { graphql } from 'gatsby'
import ClonedProductGrid from '~/components/ProductGrid/clone'
import {ProductsContainer} from '../utils/styles'

const Products = ({data}) => {
  return (
    <>      
		<SEO title="Our Bags" />
		<ProductsContainer>
		  <ClonedProductGrid />
		</ProductsContainer>
    </>
  )
}

export const query = graphql`
  query {
    source: file(relativePath: {eq: "bg/slider4.jpg"}, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        fluid (maxWidth: 1280, maxHeight: 620, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export default Products

