import styled from '@emotion/styled'
import { breakpoints } from '../../utils/styles'


export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;

  @media (max-width: ${breakpoints.s}px){
    grid-template-columns: repeat(1, 1fr);
    margin-left: 50%;
    transform: translateX(-50%);
  }
  @media (max-width: ${breakpoints.xs}px){
    grid-template-columns: repeat(1, 1fr);
    margin-left: 47%;
    transform: translateX(-55%);
  }
`

export const Product = styled.div`
  display: flex;
  min-height: 50%;
  flex-direction: column;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  margin-bottom: 2.8125rem;
  & div {
    margin-left: 50%;
    transform: translateX(-50%);
  }
`

export const Title = styled.span`
  font-family: 'Open Sans', sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  color: #414141;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
`

export const PriceTag = styled.span`
	font-family: 'Fjalla One', sans-serif;
	font-size: 1.2625rem;
	color: #fd006b;
	margin-top: 13px;
	text-align: center;
`
export const Cell = styled.div`
  border-style: solid;
  border-width: 2px;
  border-color: #f0ead6;
  -webkit-transition: all 100ms;
	-moz-transition: all 100ms;
	-ms-transition: all 100ms;
	-o-transition: all 100ms;
	transition: all 100ms;
  &:hover{
    border: solid 5px #f0ead6;
    box-shadow: 1px 1px 5px #403f43;
    transform: scale(1.02);
  }
`
export const GridContainer = styled.div`
	margin: 0 auto;
	width: 100%;
`